import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./sections/Navbar";
import AboutUs from "./sections/About";
import Services from "./sections/Services";
import WhyChooseUs from "./sections/WhyChoose";
import Banner from "./sections/Banner";
import Testimonials from "./sections/Clients";
import Footer from "./sections/Footer";
import Awards from "./sections/Awards";
import ContactUs from "./sections/Contact";
import HeroBanner from "./sections/HeroBanner";

function App() {
  return (
    <div className="App">
      <Navbar /> {/* Include the Navbar at the top of the page */}
      <HeroBanner />
      <AboutUs />
      <Services />
      <Awards />
      <WhyChooseUs />
      <Banner />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
