import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container, Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

function Services() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <section className="services py-5 bg-light">
      <Container>
        <h2 className="text-center mb-5">Our Services</h2>
        <Row>
          {/* Exhibition and Event Logistics */}
          <Col md={4} className="mb-4" data-aos="fade-up">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/258626/pexels-photo-258626.jpeg"
                alt="Exhibition and Event Logistics"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>Exhibition and Event Logistics</Card.Title>
                <Card.Text>
                  We specialize in the transportation and handling of goods for
                  exhibitions and events, ensuring timely and secure delivery.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* GCC Trucking */}
          <Col md={4} className="mb-4" data-aos="fade-up" data-aos-delay="100">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/14005602/pexels-photo-14005602.jpeg"
                alt="GCC Trucking"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>GCC Trucking</Card.Title>
                <Card.Text>
                  Our GCC trucking service provides reliable and efficient
                  transportation across the Gulf Cooperation Council countries.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Storage and Warehousing */}
          <Col md={4} className="mb-4" data-aos="fade-up" data-aos-delay="200">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/4483610/pexels-photo-4483610.jpeg"
                alt="Storage and Warehousing"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>Storage and Warehousing</Card.Title>
                <Card.Text>
                  Our secure and spacious warehousing solutions provide safe
                  storage for your goods with state-of-the-art facilities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Air Freight Forwarding */}
          <Col md={4} className="mb-4" data-aos="fade-up" data-aos-delay="300">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/358319/pexels-photo-358319.jpeg"
                alt="Air Freight Forwarding"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>Air Freight Forwarding</Card.Title>
                <Card.Text>
                  We offer fast and reliable air freight forwarding services,
                  ensuring your goods are delivered quickly and safely
                  worldwide.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Ocean Freight Forwarding */}
          <Col md={4} className="mb-4" data-aos="fade-up" data-aos-delay="400">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg"
                alt="Ocean Freight Forwarding"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>Ocean Freight Forwarding</Card.Title>
                <Card.Text>
                  Our ocean freight forwarding services offer cost-effective and
                  efficient shipping solutions for large volumes of cargo.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Project and Heavy Cargo Handling */}
          <Col md={4} className="mb-4" data-aos="fade-up" data-aos-delay="500">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/28438325/pexels-photo-28438325.jpeg"
                alt="Project and Heavy Cargo Handling"
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>Project and Heavy Cargo Handling</Card.Title>
                <Card.Text>
                  We handle project and heavy cargo shipments with expertise,
                  from planning to execution, for seamless transportation.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
