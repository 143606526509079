import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTrophy } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Awards.css"; // Custom styles for Awards

const awardsData = [
  {
    title: " Best Domestic Agent 2023",
    details:
      "ELF Shipping proudly secured first place award for 'Best Domestic Agent' at the 2023 IELA Congress in Taiwan.This recognition underscores our commitment to excellence and leadership in the logistics industry.",
    image: "/images/Awards/2023.png",
  },
  {
    title: "Second Best Domestic On-site Agent 2022",
    details:
      "ELF Shipping proudly secured the second-place award for 'Best Domestic On-site Agent' at the 2022 IELA Congress in St. Julian's, Malta. This recognition stands as a testament to our commitment to excellence in providing top-notch logistics solutions.  ",
    image: "/images/Awards/2022.png",
  },
  {
    title: " Best On Site Agent Award 2019",
    details:
      "In a remarkable achievement, ELF Shipping clinched the prestigious 'Best Domestic Agent 2019' award at the IELA Congress 2020 held in Bruges, Belgium. This recognition underscores our commitment to excellence and leadership in the logistics industry.",
    image: "/images/Awards/2019.png",
  },
  {
    title: "Second Best Domestic On-site Agent 2018",
    details:
      "In a notable accomplishment, Elf Shipping secured the esteemed '2nd Best Domestic On-site Agent 2018' award at the IELA Congress 2019 held in Venice, Italy. This recognition highlights our dedication to providing top-notch on-site logistics solutions and our commitment to excellence in the industry.",
    image: "/images/Awards/2018.png",
  },
  {
    title: "Best Domestic  Agent Award 2016",
    details:
      "In a momentous achievement, Elf Shipping secured the esteemed title of 'Best Domestic On-site Agent 2016' at the IELA Congress 2017, held in Prague, Czech Republic. This accolade underscores our dedication to excellence and leadership in the logistics arena.",
    image: "/images/Awards/2016.png",
  },
  {
    title: "Best Domestic  Agent Award 2013",
    details:
      "In a proud moment, Elf Shipping secured the esteemed 'Best Domestic On-site Agent 2013' award at the IELA Congress 2014 in Amsterdam, Netherlands. This accolade reflects our dedication to delivering top-notch on-site logistics solutions and marks a significant milestone in our journey of excellence in the logistics industry.",
    image: "/images/Awards/2013.png",
  },
];

function Awards() {
  return (
    <section className="awards py-5">
      <div className="container">
        <h2 className="text-center mb-5 section-title" data-aos="fade-up">
          Our Awards
        </h2>
        <div className="row">
          {awardsData.map((award, index) => (
            <div
              className="col-md-4 mb-4"
              key={index}
              data-aos="zoom-in"
              data-aos-delay={index * 150}
            >
              <div className="card award-card h-100">
                <img
                  src={award.image}
                  className="card-img-top"
                  alt={award.title}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <FaTrophy className="me-2" />
                    {award.title}
                  </h5>
                  <p className="card-text">{award.details}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Awards;
