import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaCheckCircle } from "react-icons/fa"; // Importing an icon from react-icons

function WhyChooseUs() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <section className="why-choose-us py-5" style={{ background: "#f9f9f9" }}>
      <Container>
        <h2
          className="text-center mb-5"
          data-aos="fade-up"
          style={{ fontWeight: "bold", fontSize: "2.5rem" }}
        >
          Why Choose Us
        </h2>
        <Row className="align-items-center">
          {/* Key Benefits and Features */}
          <Col md={6} data-aos="fade-right">
            <Row className="gy-4">
              <Col sm={12} md={6} data-aos="zoom-in" data-aos-delay="100">
                <Card
                  className="h-100 shadow-sm border-0"
                  style={{ borderRadius: "15px" }}
                >
                  <Card.Img
                    variant="top"
                    src="https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg"
                    alt="Reliability and Expertise"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                      <FaCheckCircle className="me-2 text-primary" />{" "}
                      Reliability and Expertise
                    </Card.Title>
                    <Card.Text>
                      Over 20 years of experience in the logistics industry with
                      a proven track record of reliable service and expertise.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={12} md={6} data-aos="zoom-in" data-aos-delay="200">
                <Card
                  className="h-100 shadow-sm border-0"
                  style={{ borderRadius: "15px" }}
                >
                  <Card.Img
                    variant="top"
                    src="https://images.pexels.com/photos/1342460/pexels-photo-1342460.jpeg"
                    alt="Global Network"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                      <FaCheckCircle className="me-2 text-primary" /> Global
                      Network
                    </Card.Title>
                    <Card.Text>
                      We have a vast global network, ensuring seamless
                      transportation and logistics solutions for any
                      destination.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={12} md={6} data-aos="zoom-in" data-aos-delay="300">
                <Card
                  className="h-100 shadow-sm border-0"
                  style={{ borderRadius: "15px" }}
                >
                  <Card.Img
                    variant="top"
                    src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg"
                    alt="Advanced Technology"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                      <FaCheckCircle className="me-2 text-primary" /> Advanced
                      Technology
                    </Card.Title>
                    <Card.Text>
                      We utilize advanced technology to provide real-time
                      tracking, seamless communication, and enhanced security.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={12} md={6} data-aos="zoom-in" data-aos-delay="400">
                <Card
                  className="h-100 shadow-sm border-0"
                  style={{ borderRadius: "15px" }}
                >
                  <Card.Img
                    variant="top"
                    src="https://images.pexels.com/photos/3823488/pexels-photo-3823488.jpeg"
                    alt="Customer Satisfaction"
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center">
                      <FaCheckCircle className="me-2 text-primary" /> Customer
                      Satisfaction
                    </Card.Title>
                    <Card.Text>
                      99% customer satisfaction rate achieved through our
                      dedication to delivering exceptional service.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          {/* Customer Testimonials or Statistics */}
          <Col md={6} data-aos="fade-left" data-aos-delay="500">
            <div
              className="p-4"
              style={{
                background: "#fff",
                borderRadius: "15px",
                boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3
                data-aos="fade-up"
                data-aos-delay="600"
                style={{ fontWeight: "bold" }}
              >
                Our Commitment to Excellence
              </h3>
              <p data-aos="fade-up" data-aos-delay="700">
                At ELF Shipping, we take pride in our ability to provide
                efficient, reliable, and cost-effective logistics solutions. Our
                commitment to excellence is reflected in our high customer
                satisfaction rates and the large number of shipments handled
                every year.
              </p>
              <ul
                className="list-unstyled"
                data-aos="fade-up"
                data-aos-delay="800"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li>
                  <strong>5,000+</strong> Shipments Handled Annually
                </li>
                <li>
                  <strong>99%</strong> Customer Satisfaction Rate
                </li>
                <li>
                  <strong>24/7</strong> Customer Support
                </li>
                <li>
                  <strong>20+</strong> Years of Experience in Logistics
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyChooseUs;
