import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import "./HeroBanner.css"; // Custom styles

const HeroBanner = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with custom duration
  }, []);

  return (
    <section
      className="hero-banner d-flex align-items-center"
      style={{ backgroundColor: "#051B47" }}
    >
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side Content */}
          <div className="col-md-6" data-aos="fade-right">
            <h1 className="text-white mb-4">
              Exhibition logistics & freight experts Since 2012
            </h1>

            <p className="text-white mb-4">
              With decades of experience, we specialize in seamlessly managing
              the intricate logistics of exhibitions, ensuring smooth
              transportation, setup, and coordination for events of all scales.
            </p>
            <a
              href="tel:+97148834444"
              className="btn btn-light text-primary btn-lg"
            >
              Learn More
            </a>
          </div>

          {/* Right Side Image */}
          <div className="col-md-6 text-center" data-aos="fade-left">
            <img
              src={`${process.env.PUBLIC_URL}/images/Hero/hero.png`} // Correct image path usage
              alt="Exhibition Logistics"
              className="img-fluid"
              style={{ maxHeight: "500px" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
