import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import emailjs from "emailjs-com";
import AOS from "aos";
import "aos/dist/aos.css";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "", // Add phone to the form data state
    message: "",
  });
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  // EmailJS service configuration
  const serviceID = "service_2pcrlfb"; // Replace with your EmailJS service ID
  const templateID = "template_4yxq23d"; // Replace with your EmailJS template ID
  const userID = "PAkZNO_-KlcXsn0Fz";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(serviceID, templateID, formData, userID)
      .then((result) => {
        console.log(result.text);
        setSent(true);
        setFormData({ name: "", email: "", phone: "", message: "" });
      })
      .catch((error) => {
        console.error(error.text);
        setError("Failed to send message. Please try again.");
      });
  };

  return (
    <section className="contact-us py-5">
      <div className="container">
        <div className="row">
          {/* Address Section */}
          <div className="col-md-6" data-aos="fade-right">
            <h2 className="mb-4">Contact Us</h2>
            <p>
              <strong>Address:</strong>
              <br />
              ELF Shipping LLC
              <br />
              PLOT : TP071003A
              <br />
              National Industries Park
              <br />
              Dubai,UAE
            </p>
            <p>
              <strong>Email:</strong>
              <br />
              info@elfshipping.com
            </p>
            <p>
              <strong>Phone:</strong>
              <br />
              +97148834444
            </p>
          </div>

          {/* Contact Form Section */}
          <div className="col-md-6" data-aos="fade-left">
            <h2 className="mb-4">Send Us a Message</h2>
            {sent && (
              <div className="alert alert-success">
                Message sent successfully!
              </div>
            )}
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="form-control"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
