import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
    });
  }, []);

  return (
    <footer
      className="footer py-4"
      style={{ backgroundColor: "#0a1a2f", color: "white" }}
    >
      <div className="container">
        <div className="row">
          {/* Address Section */}
          <div className="col-md-4 mb-3" data-aos="fade-up">
            <h5>Address</h5>
            <p>
              <a
                href="https://maps.app.goo.gl/etvu4vg8zXYuRQNb8"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
                style={{ textDecoration: "underline" }}
              >
                ELF Shipping LLC PLOT : TP071003A National Industries Park
                Dubai, UAE
              </a>
            </p>
          </div>

          {/* Contact Section */}
          <div
            className="col-md-4 mb-3"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h5>Contact</h5>
            <p>
              Email:{" "}
              <a
                href="mailto:info@elfshipping.com"
                className="text-white"
                style={{ textDecoration: "underline" }}
              >
                info@elfshipping.com
              </a>
            </p>
            <p>
              Phone:{" "}
              <a
                href="tel:+97148834444"
                className="text-white"
                style={{ textDecoration: "underline" }}
              >
                +97148834444
              </a>
            </p>
          </div>

          {/* Social Media Section */}
          <div
            className="col-md-4 mb-3"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h5>Follow Us</h5>
            <div className="d-flex justify-content-center">
              <a
                href="https://www.facebook.com/ELFShipping"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white me-3"
                aria-label="Facebook"
              >
                <FaFacebookF size={24} />
              </a>

              <a
                href="https://www.linkedin.com/company/elf-shipping-llc/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white me-3"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn size={24} />
              </a>
              <a
                href="https://www.instagram.com/elfshipping/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
                aria-label="Instagram"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Notice */}
        <div
          className="text-center mt-4"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <p className="mb-0">&copy; 2024 ELF Shipping. All rights reserved.</p>
          <p className="mb-0">Privacy Policy | Terms of Service</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
