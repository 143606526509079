import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUs() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <section className="about-us py-5">
      <Container>
        <Row className="align-items-center">
          {/* Content Section */}
          <Col md={6} data-aos="fade-right">
            <h2>About Us</h2>
            <p>
              ELF Shipping is a leading logistics company providing top-notch
              logistics and supply chain solutions across the globe. We are
              committed to delivering your cargo on time, every time, with our
              exceptional range of services.
            </p>
            <p>
              Our team of experienced professionals and state-of-the-art
              facilities ensure a seamless and reliable logistics experience for
              our clients. Whether it's exhibition logistics, freight
              forwarding, or heavy cargo handling, we have you covered.
            </p>
          </Col>

          {/* Image Section */}
          <Col md={6} data-aos="fade-left">
            <img
              src="https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg"
              alt="About Us"
              className="img-fluid"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutUs;
