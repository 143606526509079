import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Testimonials() {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
    });
  }, []);

  // Updated testimonials data without photos
  const testimonials = [
    {
      quote:
        "ELF Shipping made our logistics operations smooth and hassle-free. Excellent service!",
      name: "Vedhik",
    },
    {
      quote:
        "The team at ELF Shipping was incredibly professional and efficient. I highly recommend them!",
      name: " Williams",
    },
    {
      quote:
        "Their expertise in freight forwarding and cargo handling is unmatched. A reliable partner!",
      name: "Emma ",
    },
  ];

  return (
    <section
      className="testimonials py-5"
      style={{ backgroundColor: "#f8f9fa" }}
    >
      <div className="container">
        <h2 className="text-center mb-5" data-aos="fade-up">
          Client Testimonials
        </h2>
        <div className="row">
          {testimonials.map((testimonial, index) => (
            <div
              className="col-md-4 mb-4"
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div className="card border-0 shadow h-100">
                {" "}
                {/* Added h-100 for equal height */}
                <div className="card-body text-center d-flex flex-column justify-content-center align-items-center">
                  {" "}
                  {/* Centering content */}
                  <p className="card-text fst-italic">"{testimonial.quote}"</p>
                  <h5 className="card-title mt-3">{testimonial.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
