import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Navbar() {
  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        {/* Left: Logo */}
        <a className="navbar-brand" href="#">
          <img
            src="/images/Logo/Logo.png" // Make sure to use the actual path to your logo file
            alt="ELF Logo"
            width="30"
            height="30"
            className="d-inline-block align-text-top"
          />
          {/* Gap and styled text */}
          <span
            className="ms-2"
            style={{ color: "#051B47", fontWeight: "bold" }}
          >
            ELF Shipping
          </span>{" "}
        </a>

        {/* Right: Contact Us Button */}
        <a className="btn btn-primary" href="tel:+97148834444">
          Contact Us
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
